import React from "react";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";

const HowWeHelpCard = ({
  title,
  image,
  imageAlt,
  text,
  url,
  animationClass,
}) => {
  return (
    <Col className={`mb-5 mb-lg-0 ${animationClass}`} md={6} lg={4}>
      <div className="position-relative h-100">
        <Link className="text-decoration-none" to={url}>
          <h3 className=" fs-5 pb-3 text-center">{title}</h3>
        </Link>
        <div className="hwhc-image mb-4">
          <Link to={url}>
            <GatsbyImage
              style={{ height: "390px" }}
              image={image}
              alt={imageAlt}
              className="hwhc-image"
            />
          </Link>
        </div>

        <p>{text}</p>
        <div style={{ height: "67px" }}></div>

        <Link
          className="text-decoration-none position-absolute start-0 bottom-0 lato-black  py-4"
          to={url}
        >
          Read More
        </Link>
      </div>
    </Col>
  );
};

export default HowWeHelpCard;
