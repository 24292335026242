import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
import HowWeHelp from "../../components/how-we-help";

const AimsAndObjectivesPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			Img1: wpMediaItem(title: { eq: "AO Image 1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img2: wpMediaItem(title: { eq: "AO Image 2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "AO Image 3" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img4: wpMediaItem(title: { eq: "AO Image 4" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDo0NzI=" }) {
				content
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Aims and Objectives",
				item: {
					url: `${siteUrl}/about-us/aims-and-objectives`,
					id: `${siteUrl}/about-us/aims-and-objectives`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Aims and Objectives | Friends of Eastbourne Hospital"
				description="The aims and objectives of the Friends of Eastbourne Hospital and a little bit about our history."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/aims-and-objectives`,
					title: "Aims and Objectives | Friends of Eastbourne Hospital",
					description:
						"The aims and objectives of the Friends of Eastbourne Hospital and a little bit about our history.",
					images: [
						{
							url: `${data.Img3?.localFile.publicURL}`,
							width: `${data.hImg3?.localFile.childImageSharp.original.width}`,
							height: `${data.Img3?.localFile.childImageSharp.original.height}`,
							alt: `${data.Img3?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Aims and Objectives"
						description={
							<span>
								The aims and objectives of the Friends of Eastbourne
								<br className="d-none d-md-block" /> Hospital and a little bit
								about our history.
							</span>
						}
					/>
				</section>
				<section>
					<Container>
						<Row>
							<Col className="page-content aims-and-objectives">
								<p>
									The fundamental aims and objectives of the Friends of
									Eastbourne Hospital have remained much the same since its
									formation in 1948.
								</p>
								<ul>
									<li>
										To provide funds for extra services, equipment and amenities
										for patients and staff at Eastbourne DGH and the related
										community services, and to make donations where the
										expenditure has been recommended by the NHS Trust, and has
										been approved by the Committee.{" "}
									</li>
									<li>To encourage voluntary work in the hospital.</li>
									<li>
										To provide a link between the hospital and the community.
									</li>
								</ul>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<HowWeHelp />
				</section>
				<section className="pb-5">
					<Container>
						<Row>
							<Col className="page-content aims-and-objectives">
								{parse(data.wpPageContent.content)}
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<Container fluid className="px-0 mx-0 object-cover">
						<Row className="g-0">
							<Col xs={12}>
								<GatsbyImage
									image={data.Img1.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
						<Row className="g-0">
							<Col xs={12} lg={4}>
								<GatsbyImage
									image={data.Img2.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} lg={true}>
								<GatsbyImage
									image={data.Img3.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} lg={true}>
								<GatsbyImage
									image={data.Img4.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default AimsAndObjectivesPage;
